import TagManager from 'react-gtm-module'

import { getEnv, isLocal } from '../utils/helpers'

export function init(): void {
  if (isLocal()) {
    return
  }

  const {
    googleAnalytic: { gtmId, auth, preview },
  } = getEnv()

  if (gtmId && auth && preview) {
    const tagManagerArgs = {
      gtmId,
      auth,
      preview,
    }

    TagManager.initialize(tagManagerArgs)
  }
}

export type AvailableEvent =
  | 'client_web_booking_service' // to provide select booking to GA
  | 'user_info'
  | 'user_signup' // to provide user login info to GA
  | 'client_web_refund' // to provide refund event to GA
  | 'client_web_select_ac' // to provide select aircon cleaning to GA
  | 'client_web_select_hc' // to provide select home cleaning event to GA
  | 'client_web_select_oc' // to provide select office cleaning to GA
  | 'client_web_select_uc' // to provide select upholstery cleaning to GA
  | 'client_web_select_hb' // to provide select home beauty cleaning to GA
  | 'client_web_add_to_cart_ac' // to provide aircon cleaning add_to_cart event to GA
  | 'client_web_add_to_cart_hc' // to provide home cleaning add_to_cart event to GA
  | 'client_web_add_to_cart_oc' // to provide office cleaning add_to_cart event to GA
  | 'client_web_add_to_cart_uc' // to provide upholstery cleaning add_to_cart event to GA
  | 'client_web_add_to_cart_hb' // to provide home beauty add_to_cart event to GA
  | 'client_web_add_to_cart' // to provide add_to_cart event to GA
  | 'client_web_purchase_ac' // to provide aircon cleaning purchase event to GA
  | 'client_web_purchase_hc' // to provide home cleaning purchase event to GA
  | 'client_web_purchase_oc' // to provide office cleaning purchase event to GA
  | 'client_web_purchase_uc' // to provide upholstery cleaning event purchase to GA
  | 'client_web_purchase_hb' // to provide home beauty event purchase to GA
  | 'client_web_purchase' // to provide event purchase to GA
  | 'client_web_select_hc_adhoc' // to provide event select home cleaning one time cleaning/ad hoc to GA
  | 'client_web_select_hc_fortnightly' // to provide event select home cleaning every other week/fort night to GA
  | 'client_web_select_hc_weekly' // to provide event select home cleaning once per week to GA
  | 'client_web_select_hc_weeklyplus' // to provide event select home cleaning more than once per week to GA
  | 'ga4_beautypopup_15offclick' // to provide event interest with promo home beauty
  | 'ga4_inapppopup_click' // to provide event interest promotion

export const triggerEvent = <T extends object>(
  event: AvailableEvent,
  data: T
): void => {
  window.dataLayer = window.dataLayer || []
  const dataKeys = Object.keys(data).reduce((objKey, key) => {
    objKey[key] = null

    return objKey
  }, {})
  window.dataLayer.push({ event, ...dataKeys })
  window.dataLayer.push({
    event,
    ...data,
  })
}
